export const signsQuestions = [
    {
      key: "age",
      text: "How old are you?",
      options:[
        { text: "15 or younger", score: 0, reason: "PCOS symptoms commonly appear in late teens or early twenties." },
        { text: "16-25", score: 3, reason: "This age range aligns with typical PCOS onset." },
        { text: "26-35", score: 2, reason: "Some symptoms may appear later, but this age range is still a risk period." },
        { text: "36 or older", score: 0, reason: "PCOS symptoms typically appear earlier in life." }
      ]
    },
    {
      key: "menstrualCycle",
      text: "If you are over 3 years past menarche (your first period) and not in perimenopause, how many days are there between the first day of your period and the first day of your next period?",
      options: [
        { text: "21-35 days", score: 0, reason: "A typical cycle length; irregularity is less likely." },
        { text: "Fewer than 21 days or more than 35 days", score: 2, reason: "This indicates a potential irregular menstrual cycle." },
        { text: "Very rarely or not at all", score: 3, reason: "Infrequent or absent periods are strong indicators of PCOS." }
      ]
    },
    {
      key: "difficultyGettingPregnant",
      text: "Are you currently trying to get pregnant or have you experienced difficulties conceiving in the past?",
      options: [
        { text: "No, I am not trying to get pregnant and have not experienced difficulties conceiving.", score: 0, reason: "Your answer is less likely associated with PCOS." },
        { text: "Yes, I am trying to get pregnant.", score: 2, reason: "PCOS is one of the most common causes of female infertility. Women with PCOS might have irregular or absent periods, making it difficult to conceive." },
        {text: "Yes, I have experienced difficulties conceiving in the past.", score: 2, reason: "PCOS is one of the most common causes of female infertility. Women with PCOS might have irregular or absent periods, making it difficult to conceive."}
      ]
    },
    {
      key: "hairGrowth",
      text: "Do you have excess dark, thick hair growth in any of these areas: upper lip, chin, chest, around the nipples, lower abdomen, back, upper arms, thighs, or buttocks?",
      options: [
        { text: "No", score: 0, reason: "No excess hair growth is less likely associated with PCOS." },
        { text: "Yes, with an mFG score > 6", score: 1, reason: "Mild hirsutism could be linked to PCOS." },
        { text: "Yes, with an mFG score > 8", score: 2, reason: "Moderate hirsutism is a common PCOS symptom." },
        { text: "Yes, with an mFG score > 10", score: 3, reason: "Severe hirsutism strongly suggests hormonal imbalance linked to PCOS." }
      ]
    },
    {
      key: "hairThinning",
      text: "Have you noticed thinning hair or hair loss from your head for more than three months?",
      options: [
        { text: "No", score: 0, reason: "No thinning hair is less likely associated with PCOS" },
        { text: "Yes", score: 1, reason: "Thinning hair and hair loss from the head is a potential symptom of PCOS." }
      ]
    },
    {
      key: "acneOrOilySkin",
      text: "Do you experience persistent or severe acne, even when using over-the-counter treatments?",
      options: [
        { text: "No", score: 0, reason: "No sever acne is less likely associated with PCOS." },
        { text: "Yes", score: 1, reason: "While acne can have various causes, persistent or severe acne, especially in adulthood, could be linked to hormonal imbalances associated with PCOS." }
      ]
    },
    {
      key: "weightGain",
      text: "Have you gained a significant amount of weight (e.g., more than 5% of your body weight) in a short period (e.g., six months) without explanation (e.g., changes in diet or exercise)?",
      options: [
        { text: "No", score: 0, reason: "No unexplained weight gain is less likely associated with PCOS." },
        { text: "Yes", score: 1, reason: "Unexplained weight gain is a common symptom of PCOS." }
      ]
    },
    {
      key: "familyHistory",
      text: "Do you have a family history of PCOS (mother, sister, aunt)?",
      options: [
        { text: "No", score: 0, reason: "No family history of PCOS is less likely associated with PCOS." },
        { text: "Yes", score: 1, reason: "Some research suggests family history of PCOS might indicate an increased likelihood." }
      ]
    },
    {
      key: "mentalHealthSadness",
      text: "Have you experienced feelings of sadness, hopelessness, or loss of interest in things you used to enjoy for an extended period?",
      options: [
        { text: "No", score: 0, reason: "No frequent anxiety." },
        { text: "Yes, sometimes", score: 1, reason:"Occasional anxiety may be related to PCOS." },
        { text: "Yes, frequently", score: 2, reason:"Frequent sadness or hopelessness could be a sign of underlying conditions, including PCOS."  }
      ]
    },
    {
      key: "mentalHealthAnxiety",
      text: "Do you often feel worried, anxious, or on edge?",
      options: [
        { text: "No", score: 0, reason: "No frequent anxiety."  },
        { text: "Yes, sometimes", score: 1, reason: "" },
        { text: "Yes, frequently", score: 2, reason: "" }
      ]
    }
  ];
  