import React from "react";
import event1 from "../assets/images/event-photos/event1.svg";
import event2 from "../assets/images/event-photos/event2.svg";
import event3 from "../assets/images/event-photos/event3.svg";
import event4 from "../assets/images/event-photos/event4.svg";
import { Link } from "react-router-dom";

const Events = () => {
  const eventImages = [event1, event2, event3, event4];

  return (
    <section id="events" className="col-span-6 my-16 scroll-mt-[124px] max-w-screen-2xl mx-auto ">
      <h1
        className="text-[32px] lg:text-[38px] font-bold text-center text-secondary"
        style={{ color: "#5C3411" }}
      >
        Events
      </h1>
      <div className=" mx-8 md:mx-12 lg:mx-44">
        <div className=" mt-8">
          <p className="mb-4 text-left text-[18px] lg:text-[24px] font-medium">
            At Paia, we believe in fostering a supportive community to help
            navigate the challenges of PCOS. We understand that this journey can
            be difficult, which is why we're excited to offer a range of events
            designed to provide support and connection.
          </p>
        </div>
        <div className="flex flex-col space-y-4">
          <h2 className=" text-left font-semibold italic underline mt-8 text-accent text-[28px] lg:text-[34px]">
            Recent events
          </h2>
          <h3 className="text-secondary text-[18px] font-bold lg:text-[26px]">
            Paia PCOS Walkathon - 28th September 2024
          </h3>
          <p className="text-[16px] lg:text-[24px] font-medium">
            Our Paia PCOS Walkathon brought together an incredible community to
            raise awareness and support those battling PCOS!
          </p>
        </div>
        <div className="my-6">
          <Link to="https://www.instagram.com/paiapcos/" target="_blank">
            <button
              aria-label="future events"
              className="bg-secondary w-fit rounded-[33px] lg:text-[17px] lg:text-[21px] font-regular hover:bg-secondary/80 transition duration-200 text-white py-2 px-8"
            >
              Find out about future events
            </button>
          </Link>
        </div>
        <div className="py-8 overflow-x-scroll flex gap-2">
          {eventImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Event ${index + 1}`}
              className="w-auto h-[15rem] lg:h-[19rem]"
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Events;
