import React from "react";
import landingImage2 from "../assets/images/landingImage2.png";
import landingImage1 from "../assets/images/landingImage1.png";
import PulsatingButton from "./buttons/PulsatingButton";

const Hero = () => {
  const handleScrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section
      id="landing"
      aria-label="Hero section showcasing Paia's services and community"
      className="flex flex-col items-center justify-center pt-32 lg:pt-24"
    >
      <div className="w-screen max-w-screen-2xl mx-auto flex flex-col items-center lg:flex-row lg:items-start justify-center lg:justify-between px-4 md:px-8 lg:px-20 pt-16 pb-24 lg:py-32">
      {/* Text Container */}
      <div className="flex flex-col items-center lg:items-start text-center lg:text-left lg:w-1/2 lg:px-12">
        <h1 className="font-light text-[16px] mt-8 lg:text-[22px] italic">
          Your companion in hormonal health
        </h1>
        <h2 className="text-secondary max-w-[32rem] text-[32px] lg:text-[40px] font-semibold text-center lg:text-left leading-snug px-4 md:px-14 lg:px-0">
          At Paia, we empower women with PCOS through awareness, guidance, and
          personalised care.
        </h2>
        <div className="mt-14">
          <PulsatingButton onClick={() => handleScrollToSection("events")}>
            <p className="font-medium text-[18px] lg:text-[22px]">Join Our Community</p>
          </PulsatingButton>
        </div>
      </div>

      {/* Image Container for Large Screens */}
      <div className="w-full hidden lg:flex justify-center lg:w-1/2 px-4">
        <img
          src={landingImage2}
          alt="Landing"
          className="w-auto lg:h-[28rem] max-w-lg h-auto"
        />
      </div>

      {/* Image Container for Mobile Screens */}
      <div className="w-full lg:hidden flex justify-center mt-16 md:mt-16">
        <img
          src={landingImage1}
          alt="Landing"
          className="w-auto h-[18rem] md:h-[24rem] max-w-lg" 
        />
      </div>
      </div>
    </section>
  );
};

export default Hero;
