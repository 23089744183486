import "./App.css";
import * as React from 'react'
import Root from "./routes/Root"
import ErrorPage from "./error-page"
import Learn from "./routes/learn"
import Landing from "./routes/Landing";
import Consultation from "./routes/Consultation";
import Calculator from "./routes/Calculator";
import Signs from "./routes/Signs";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Landing/>,
      },
      {
        path: "learn",
        element: <Learn/>,
      },
      {
        path: "consultation", 
        element: <Consultation />,
      },
      {
        path: "pcos-types",
        element: <Calculator/>,
      },
      {
        path: "signs",
        element: <Signs/>,
      },
    ],
  },
]);


function App() {
  
  return (
    <React.StrictMode>
    <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;

