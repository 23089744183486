import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <Link to='https://decolonizepalestine.com/' target="_blank">
    <div className="fixed flex flex-col items-center -top-1 left-0 w-full h-[2.4rem] bg-[#355227] text-white text-center py-[0.7rem] z-50">
      <p className="text-sm md:text-md font-medium">
        Free Palestine ❤️
      </p>
    </div>
    </Link>

  );
};

export default Banner;
