import React from "react";

const About = () => {
  return (
    <section
      id="about"
      aria-labelledby="about-heading"
      className="w-screen shadow-lg scroll-mt-[124px] lg:pt-28 pb-16"
      style={{ backgroundColor: "#FCF1E7" }}
    >
      <div className="w-screen max-w-screen-2xl mx-auto px-8 md:px-12 pt-[2rem] lg:pt-0 lg:px-0">
        {/* Mobile border */}
        <div className="rounded-lg bg-[#F0B094] h-[0.24rem] lg:hidden"></div>
        <div className="lg:px-44 text-left text-[18px] lg:text-[24px]">
          {/* Main heading for the About section */}
          <h2
            id="about-heading"
            className="text-accent text-[32px] lg:text-[38px] mt-6 md:mt-8 font-bold"
          >
            About Us
          </h2>
          {/* Desktop border */}
          <div className="rounded-lg bg-[#F0B094] h-[0.24rem] hidden lg:block"></div>
          <h3 className="text-[24px] font-extrabold italic mt-6 mb-4 lg:my-8">
            Maham's Story
          </h3>
          <p>
            My journey began in 2018 when I noticed my periods were
            inconsistent. After enduring several misdiagnoses and long waits
            through the NHS, I started doing my own research. It was only
            through the privilege of accessing private healthcare that I was
            finally diagnosed with PCOS. This experience made me realise how
            many, like myself, often feel stigmatised and isolated, especially
            without the same resources.
          </p>
          <p className="mt-4">
            Managing PCOS was another challenge—online resources, social media,
            and apps offered limited support, making it stressful to find a
            personalised plan.
          </p>
          <p className="mt-4 mb-8">
            Inspired by this, co-founders Sabihah, Srezon, Marah, and I
            developed <span className="font-semibold">Paia PCOS</span>, a{" "}
            <span className="font-semibold">female-led</span> wellness app
            providing <span className="font-semibold">knowledge</span>,{" "}
            <span className="font-semibold">resources</span>, and a{" "}
            <span className="font-semibold">supportive community</span> to make
            PCOS management <span className="font-semibold">accessible</span> for everyone!
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
