import React, { useState } from "react";
import { signsQuestions } from "./signsQuestions";
import { Link } from "react-router-dom";
import { AnimatedCircularProgressBar } from "../AnimatedCircularProgressBar";

interface QuizState {
  [key: string]: number | null;
}

const SignsQuiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<QuizState>({});
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [totalScore, setTotalScore] = useState(0);

  const [reasons, setReasons] = useState<string[]>([]);

const handleAnswer = (score: number, reason: string) => {
  const questionKey = signsQuestions[currentQuestion].key;

  setAnswers((prev) => ({
    ...prev,
    [questionKey]: score,
  }));

  // Track the reason for the selected answer
  setReasons((prevReasons) => [...prevReasons, reason]);

  setTotalScore((prev) => prev + score);

  if (currentQuestion < signsQuestions.length - 1) {
    setCurrentQuestion(currentQuestion + 1);
  } else {
    setQuizCompleted(true);
  }
};


  const getPCOSRiskLevel = () => {
    if (totalScore >= 8) {
      return "High: You have a strong probability of having PCOS. It is recommended that you schedule an appointment with your GP to discuss your symptoms.";
    } else if (totalScore >= 4) {
      return "Moderate: There's a chance you may have PCOS. Consider scheduling an appointment with your GP to discuss your symptoms.";
    } else {
      return "Low: It's unlikely that you have PCOS, but it's still possible.";
    }
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="self-start my-14">
        <Link to="/">
          <p className="text-border self-start px-24 font-semibold text-[#C9C9C9] hover:text-black transition duration-200 cursor-pointer">
            Back home
          </p>
        </Link>
      </div>
      {!quizCompleted ? (
        <div className="flex flex-col space-y-16 w-2/3">
          <div className="flex justify-end">
            <AnimatedCircularProgressBar
              max={signsQuestions.length}
              value={currentQuestion}
              min={0}
              gaugePrimaryColor={"#C9C9C9"}
              gaugeSecondaryColor={"#E38056"}
            />
          </div>
          <div className="h-[10rem]">
            <p className="text-3xl font-bold text-center">
              {signsQuestions[currentQuestion].text}
            </p>
          </div>
          <div className="flex flex-col items-center justify-center w-full gap-4">
            {signsQuestions[currentQuestion].options.map((option, index) => (
              <button
                key={index}
                className="px-8 py-3 text-xl text-background rounded-lg bg-secondary"
                onClick={() => handleAnswer(option.score, option.reason)}
              >
                {option.text}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <div className="space-y-24 text-center">
          <h2 className="text-3xl font-bold">Your PCOS Risk Assessment</h2>
          <p className="text-2xl font-semibold">Total Score: {totalScore}</p>
          <p className="text-4xl text-secondary font-bold">{getPCOSRiskLevel()}</p>
          <p className="text-lg">
            This is a rough estimate based on your answers. If you're concerned about your health, please consult a healthcare professional.
          </p>
          <p>{reasons}</p>
        </div>
      )}
    </div>
  );
};

export default SignsQuiz;
