import React from "react";
import teamMember1 from "../assets/images/mahamFrame.png";
import teamMember2 from "../assets/images/marahFrame.png";
import teamMember3 from "../assets/images/sabihahFrame.png";
import teamMember4 from "../assets/images/srezonFrame.png";

const Team = () => {
  return (
    <section
      id="team"
      className="col-span-6 flex flex-col items-center justify-center py-20 bg-white"
    >
      <h2 className="text-[32px] text-secondary lg:text-[38px] italic font-bold pb-4 lg:py-12">
        Meet the Paia Team!
      </h2>

      <div className="hidden md:flex items-start justify-center space-x-16 shadow-lg rounded-[70px] px-[5rem] py-[2rem] bg-secondary">
        <div className="flex flex-col items-center m-4 space-y-6 text-[22px]">
          <p className="italic font-light" style={{ color: "#FCF1E7" }}>
            Co-Founder
          </p>
          <img
            src={teamMember1}
            alt="Maham"
            className="w-auto h-[9rem] mt-2 mb-2"
            style={{ borderRadius: "50%" }}
          />
          <div className="w-1/2 text-center">
            <p className="font-bold" style={{ color: "#FCF1E7" }}>
              Maham
            </p>
            <p style={{ color: "#FCF1E7" }} className="">
              Growth & Operations
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center m-4 space-y-6 text-[22px]">
          <p className="italic font-light" style={{ color: "#FCF1E7" }}>
            Co-Founder
          </p>
          <img
            src={teamMember2}
            alt="Marah"
            className="w-auto h-[9rem] mt-2 mb-2"
            style={{ borderRadius: "50%" }}
          />
          <div className="w-1/2 text-center">
            <p className="font-bold" style={{ color: "#FCF1E7" }}>
              Marah
            </p>
            <p style={{ color: "#FCF1E7" }} className="font-light">
              Software Engineer
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center m-4 space-y-6 text-[22px]">
          <p className="italic font-light" style={{ color: "#FCF1E7" }}>
            Co-Founder
          </p>
          <img
            src={teamMember3}
            alt="Sabihah"
            className="w-auto h-[9rem] mt-2 mb-2"
            style={{ borderRadius: "50%" }}
          />
          <div className="w-1/2 text-center">
            <p className="font-bold" style={{ color: "#FCF1E7" }}>
              Sabihah
            </p>
            <p style={{ color: "#FCF1E7" }} className="font-light">
              Designer
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center m-4 space-y-6 text-[22px]">
          <p className="italic font-light" style={{ color: "#FCF1E7" }}>
            Co-Founder
          </p>
          <img
            src={teamMember4}
            alt="Srezon"
            className="w-auto h-[9rem] mt-2 mb-2"
            style={{ borderRadius: "50%" }}
          />
          <div className="w-1/2 text-center">
            <p className="font-bold" style={{ color: "#FCF1E7" }}>
              Srezon
            </p>
            <p style={{ color: "#FCF1E7" }} className="font-light">
              Software Engineer
            </p>
          </div>
        </div>
      </div>

      <div className="md:hidden w-full overflow-x-scroll text-[17px]">
        <div className="flex">
          <div className="flex-shrink-0 flex flex-col items-center justify-start space-y-6 m-4 py-[2.4rem] px-12 rounded-[80px] bg-secondary">
            <p className="italic mt-4" style={{ color: "#FCF1E7" }}>
              Co-Founder
            </p>
            <img
              src={teamMember1}
              alt="Maham"
              className="w-auto h-[8rem] mt-2 mb-2"
              style={{ borderRadius: "50%" }}
            />
            <div
              className="max-w-[6rem] font-light leading-[1.3rem] text-center text-wrap"
              style={{ wordWrap: "break-word", display: "inline-block" }}
            >
              <p className="font-bold" style={{ color: "#FCF1E7" }}>
                Maham
              </p>
              <p style={{ color: "#FCF1E7" }}>Growth & Operations</p>
            </div>
          </div>

          <div className="flex-shrink-0 flex flex-col items-center justify-start space-y-6 m-4 py-[2.4rem] px-12 rounded-[80px] bg-secondary">
            <p className="italic mt-4" style={{ color: "#FCF1E7" }}>
              Co-Founder
            </p>
            <img
              src={teamMember2}
              alt="Marah"
              className="w-auto h-[8rem] mt-2 mb-2"
              style={{ borderRadius: "50%" }}
            />
            <div
              className="max-w-[6rem] font-light leading-[1.3rem] text-center text-wrap"
              style={{ wordWrap: "break-word", display: "inline-block" }}
            >
              <p className="font-bold" style={{ color: "#FCF1E7" }}>
                Marah
              </p>
              <p style={{ color: "#FCF1E7" }}>Software Engineer</p>
            </div>
          </div>

          <div className="flex-shrink-0 flex flex-col items-center justify-start space-y-6 m-4 py-[2.4rem] px-12 rounded-[80px] bg-secondary">
            <p className="italic mt-4" style={{ color: "#FCF1E7" }}>
              Co-Founder
            </p>
            <img
              src={teamMember3}
              alt="Sabihah"
              className="w-auto h-[8rem] mt-2 mb-2"
              style={{ borderRadius: "50%" }}
            />
            <div
              className="max-w-[6rem] font-light leading-[1.3rem] text-center text-wrap"
              style={{ wordWrap: "break-word", display: "inline-block" }}
            >
              <p className="font-bold" style={{ color: "#FCF1E7" }}>
                Sabihah
              </p>
              <p style={{ color: "#FCF1E7" }}>Designer</p>
            </div>
          </div>

          <div className="flex-shrink-0 flex flex-col items-center justify-start space-y-6 m-4 py-[2.4rem] px-12 rounded-[80px] bg-secondary">
            <p className="italic mt-4" style={{ color: "#FCF1E7" }}>
              Co-Founder
            </p>
            <img
              src={teamMember4}
              alt="Maham"
              className="w-auto h-[8rem] mt-2 mb-2"
              style={{ borderRadius: "50%" }}
            />
            <div
              className="max-w-[6rem] font-light leading-[1.3rem] text-center text-wrap"
              style={{ wordWrap: "break-word", display: "inline-block" }}
            >
              <p className="font-bold" style={{ color: "#FCF1E7" }}>
                Srezon
              </p>
              <p style={{ color: "#FCF1E7" }}>Software Engineer</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
