import React from "react";
import Marquee from "react-fast-marquee";
import send from "../assets/icons/insta-send.png";
import like from "../assets/icons/insta-heart.png";
import comment from "../assets/icons/insta-comment.png";
import save from "../assets/icons/insta-save.png";
import logo from "../assets/icons/logo-circle.png";
import { Link } from "react-router-dom";

interface Post {
  imageUrl: string;
  likes: number;
}

const InstagramPosts = ({ posts }: { posts: Post[] }) => {
  return (
    <div className="w-full z-40 max-w-screen-2xl mx-auto ">
      <Marquee speed={50}>
        {posts.map((post: Post, index: number) => (
          <Link to="https://www.instagram.com/paiapcos/" target="_blank" key={index}>
            <div
              key={index}
              className="flex flex-col items-center mx-4 bg-white px-8 pb-10 pt-4 rounded-md"
            >
              <div className="w-full flex justify-between items-center my-2">
                <div className="flex items-center justify-start w-full gap-2">
                  <img src={logo} alt="logo" className="h-[1.4rem] w-auto" />
                  <p className="text-sm font-semibold">paiapcos</p>
                </div>
              </div>
              <img
                src={post.imageUrl}
                alt={`Post ${index + 1}`}
                className="w-64 h-64 object-cover"
              />
              <div className="flex justify-between items-center w-full mt-2">
                <div className="flex items-center">
                  <img src={like} alt="insta heart" className="h-[1rem]" />
                  <img src={comment} alt="insta comment" className="h-[2rem]" />
                  <img src={send} alt="insta send" className="h-[1rem]" />
                </div>
                <img src={save} alt="insta save" className="h-[1rem]" />
              </div>
            </div>
          </Link>
        ))}
      </Marquee>
    </div>
  );
};

export default InstagramPosts;
