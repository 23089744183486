import React, {useState} from 'react'
import ShinyButton from '../components/buttons/ShinyButton'
import SignsQuiz from '../components/Calculator/SignsQuiz';

const Signs = () => {

    const [started, setStarted] = useState(false);
  return (
    <div className="min-h-screen w-screen flex flex-col items-center justify-center px-32">
    {started ? (
      <SignsQuiz/>
    ) : (
      <div className="flex flex-col space-y-8 my-20 px-20">
        <h1 className="text-secondary text-4xl font-bold">
        If you suspect you may have PCOS, consult a healthcare professional for proper diagnosis and treatment.
        </h1>
        <p className="text-3xl text-border font-semibold">
        This calculator is designed for educational purposes only and should not be used for self-diagnosis.
        </p>
        <div className="py-10 flex flex-col space-y-10 font-semibold">
          <p className="text-border">
            Note: Your data stays private — nothing is stored or shared.
          </p>
          <div className="w-[200px]">
            <button onClick={() => setStarted(true)}>
              <ShinyButton
                backgroundColor="#E38056"
                textColor="#FCF1E7"
                secondaryColor="#5C3411"
              >
                <p>Get started</p>
              </ShinyButton>
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
  )
}

export default Signs
