import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className="mx-auto flex flex-col items-center justify-center min-h-screen space-y-8">
      <h1 className="font-bold text-2xl">Oops!</h1>
      <p className="font-medium text-lg">Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}