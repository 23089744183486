import post1 from "../assets/images/posts/post1.svg"
import post2 from "../assets/images/posts/post2.svg"
import post3 from "../assets/images/posts/post3.svg"
import post4 from "../assets/images/posts/post4.svg"
import post5 from "../assets/images/posts/post5.svg"
import post6 from "../assets/images/posts/post6.svg"
import post7 from "../assets/images/posts/post7.svg"
import post8 from "../assets/images/posts/post8.svg"

export const posts = [
    {
        imageUrl: post1,
        likes: 120,
    },
    {
        imageUrl: post2,
        likes: 120,
    },
    {
        imageUrl: post3,
        likes: 120,
    },
    {
        imageUrl: post4,
        likes: 120,
    },
    {
        imageUrl: post5,
        likes: 120,
    },
    {
        imageUrl: post6,
        likes: 120,
    },
    {
        imageUrl: post7,
        likes: 120,
    },
    {
        imageUrl: post8,
        likes: 120,
    },

]