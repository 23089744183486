import React, { useState } from "react";
import paia from "../assets/icons/paia.png";
import { Link } from "react-router-dom";
import Banner from "./Banner";
import AnimatedHamburgerBtn from "./buttons/AnimatedHamburgerBtn";
import { useNavigate } from "react-router-dom";

interface NavbarProps {
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMenuOpen: boolean;
}

const Navbar: React.FC<NavbarProps> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const menu = document.getElementById("mobile-menu");
    const button = document.getElementById("hamburger-btn");
    if (
      menu &&
      !menu.contains(event.target as Node) &&
      button &&
      !button.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleScrollToSection = (sectionId: string) => {
    navigate("/"); // Navigate to the home page

    // Use setTimeout to ensure the navigation completes first
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        setIsOpen(false); // Close mobile menu if open
      }
    }, 0); // Delay the scroll until the page renders fully
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Banner />
      <nav className="fixed top-[1.5rem] w-full bg-[#FCF1E7] z-40 flex items-end justify-center md:pb-4 px-8 md:pr-32 md:pl-32 h-[7.75rem] shadow-sm">
        <div className="flex flex-row items-end md:gap-8 lg:justify-between w-full  max-w-screen-2xl">
          <div className="hidden md:block pt-4">
            <Link to="/">
              <img
                src={paia}
                alt="Paia Logo"
                className="w-[4rem] md:w-[6rem] lg:w-[9rem] h-auto object-contain"
              />
            </Link>
          </div>

          <div className="hidden md:flex lg:space-x-4 text-[clamp(12px,2vw,24px)] whitespace-nowrap md:mr-36 lg:mr-0">
            <button
              onClick={() => handleScrollToSection("about")}
              className="text-black font-medium hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px] transition"
              aria-label="Navigate to About Us"
            >
              About Us
            </button>
            <button
              onClick={() => handleScrollToSection("connect")}
              className="text-black font-medium hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px] transition"
              aria-label="Navigate to Social Media"
            >
              Social Media
            </button>
            <button
              onClick={() => handleScrollToSection("contact")}
              className="text-black font-medium hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px] transition"
              aria-label="Navigate to Contact Us"
            >
              Contact Us
            </button>
            <button
              onClick={() => handleScrollToSection("events")}
              className="text-black font-medium hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px] transition"
              aria-label="Navigate to Events"
            >
              Events
            </button>
            <Link
              to="/learn"
              className="text-black font-medium hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px] transition"
              aria-label="Navigate to Learn"
            >
              Learn
            </Link>
          </div>

          <div className="md:hidden flex justify-center items-center w-full">
            <div className="flex-1 flex justify-center pb-[0.5rem]">
              <Link to="/">
                <img src={paia} alt="Paia Logo" className="h-[4rem] w-auto" />
              </Link>
            </div>

            <div className="mr-4">
              <div
                onClick={toggleMenu}
                aria-label="Toggle menu"
                role="button"
                id="hamburger-btn"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter") toggleMenu();
                }}
              >
                <AnimatedHamburgerBtn setIsOpen={setIsOpen} isOpen={isOpen} />
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          id="mobile-menu"
          className={`fixed top-[9.2rem] right-0 h-full w-64 bg-[#FCF1E7] transform transition-transform duration-300 ease-in-out md:hidden z-50 ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="flex flex-col items-end space-y-8 pt-16 px-4">
            {/* Menu Links */}
            <Link
              to="/learn"
              className="block text-[24px] text-black font-medium hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px]"
              onClick={() => setIsOpen(false)}
              aria-label="Navigate to Learn"
            >
              Learn
            </Link>
            <button
              className="block text-[24px] text-black font-medium hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px]"
              onClick={() => handleScrollToSection("about")}
              aria-label="Navigate to About Us"
            >
              About Us
            </button>
            <button
              className="block text-[24px] text-black font-medium hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px]"
              onClick={() => handleScrollToSection("events")}
              aria-label="Navigate to Events"
            >
              Events
            </button>
            <button
              onClick={() => handleScrollToSection("connect")}
              className="block text-[24px] text-black font-medium w-fit hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px]"
              aria-label="Navigate to Social Media"
            >
              Social Media
            </button>
            <button
              className="block text-[24px] text-black font-medium hover:bg-[#A26632] hover:text-[#FCF1E7] px-4 py-2 rounded-[30px]"
              onClick={() => handleScrollToSection("contact")}
              aria-label="Navigate to Contact Us"
            >
              Contact Us
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
