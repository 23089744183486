import React from "react";
import { motion, type AnimationProps } from "framer-motion";
import { cn } from "../../lib/utils";

const animationProps = {
  initial: { "--x": "100%", scale: 0.8 },
  animate: { "--x": "-100%", scale: 1 },
  whileTap: { scale: 0.95 },
  transition: {
    repeat: Infinity,
    repeatType: "loop",
    repeatDelay: 1,
    type: "spring",
    stiffness: 20,
    damping: 15,
    mass: 2,
    scale: {
      type: "spring",
      stiffness: 200,
      damping: 5,
      mass: 0.5,
    },
  },
} as AnimationProps;

interface ShinyButtonProps {
  children: React.ReactNode;
  className?: string;
  backgroundColor?: string;  // Background color prop
  textColor?: string;        // Text color prop
  secondaryColor?: string;   // Secondary color prop
}

const ShinyButton = ({
  children,
  className,
  backgroundColor = "transparent",  // Default to transparent if not provided
  textColor = "black",              // Default to black text if not provided
  secondaryColor = "hsl(200, 100%, 50%)", // Default secondary color
  ...props
}: ShinyButtonProps) => {
  return (
    <motion.button
      {...animationProps}
      {...props}
      className={cn(
        "relative rounded-lg px-6 py-2 font-medium backdrop-blur-xl transition-shadow duration-300 ease-in-out hover:shadow",
        className
      )}
      style={{
        backgroundColor: backgroundColor, // Set the button background color
      }}
    >
      <span
        className="relative block size-full text-sm uppercase tracking-wide"
        style={{
          color: textColor, // Apply the text color
          maskImage: `linear-gradient(-75deg, ${secondaryColor} calc(var(--x) + 20%), transparent calc(var(--x) + 30%), ${secondaryColor} calc(var(--x) + 100%))`,
        }}
      >
        {children}
      </span>
      <span
        className="absolute inset-0 z-10 block rounded-[inherit] p-px"
        style={{
          background: `linear-gradient(-75deg, ${secondaryColor}/10% calc(var(--x) + 20%), ${secondaryColor}/50% calc(var(--x) + 25%), ${secondaryColor}/10% calc(var(--x) + 100%))`,
        }}
      />
    </motion.button>
  );
};

export default ShinyButton;
