import React from "react";
import insta from "../assets/icons/insta-blk.png";
import linkedin from "../assets/icons/linkedin.png";
import facebook from "../assets/icons/facebook.png";
import twitter from "../assets/icons/twitter-logo-black.png";
import tiktok from "../assets/icons/tiktok-blk.png";
import InstagramPosts from "./InstagramPosts";
import { posts } from "../lib/constants";

const Connect = () => {
  return (
    <section
      id="connect"
      className="col-span-6 scroll-mt-[124px] pt-20 lg:pt-32"
    >
      <h2 className="font-bold text-secondary text-left px-6 leading-[2.6rem] lg:text-center text-[32px] lg:text-[38px]">
        Follow our journey on social media!
      </h2>
      <div className="flex px-6 gap-5 justify-start lg:justify-center items-center mt-10">
        <a
          href="https://www.instagram.com/paiapcos/"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer"
        >
          <img
            src={insta}
            alt="Instagram"
            className="w-auto h-[3.2rem] lg:h-[3.8rem]"
          />
        </a>
        <a
          href="https://www.tiktok.com/@paiapcos"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer"
        >
          <img
            src={tiktok}
            alt="TikTok"
            className="w-auto h-[3.2rem] lg:h-[3.8rem]"
          />
        </a>
        <a
          href="https://x.com/PaiaPcos"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer"
        >
          <img
            src={twitter}
            alt="Twitter"
            className="w-auto h-[3.2rem] lg:h-[3.8rem]"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/paia-pcos/"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer"
        >
          <img
            src={linkedin}
            alt="LinkedIn"
            className="w-auto h-[3.2rem] lg:h-[3.8rem]"
          />
        </a>
        <a
          href="https://www.facebook.com/people/Paia/61561575683991/"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer"
        >
          <img
            src={facebook}
            alt="Facebook"
            className="w-auto h-[3.2rem] lg:h-[3.8rem]"
          />
        </a>
      </div>
      <div className="bg-popover py-16 my-12">
        <InstagramPosts posts={posts}/>
      </div>
    </section>
  );
};

export default Connect;
