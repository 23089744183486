import React, { useState, useEffect } from "react";
import { questions } from "./questions";
import { Link } from "react-router-dom";
import { AnimatedCircularProgressBar } from "../AnimatedCircularProgressBar";

interface QuizState {
  [key: string]: boolean | null;
}

interface PCOSTypeResult {
  A: number;
  B: number;
  C: number;
  D: number;
}

const Quiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<QuizState>({});
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [email, setEmail] = useState("");

  const handleAnswer = (answer: boolean) => {
    const questionKey = questions[currentQuestion].key;

    setAnswers((prev) => ({
      ...prev,
      [questionKey]: answer,
    }));

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setQuizCompleted(true);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const calculatePCOSTypeScores = (): PCOSTypeResult => {
    const { highAndrogens, elevatedTestosterone, irregularPeriods, polycysticOvaries, hirsutism } = answers;

    const scores: PCOSTypeResult = {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    };

    // Section 1: Hyperandrogenism (HA)
    if (highAndrogens) {
      scores.A += 3;
      scores.B += 3;
      scores.C += 3;
    } else {
      scores.D += 3; // No diagnosis of high androgens is phenotype D
    }

    // Section 2: Elevated Testosterone
    if (elevatedTestosterone) {
      scores.A += 3;
      scores.B += 3;
      scores.C += 3;
    } else {
      scores.D += 3; // No elevated testosterone indicates D phenotype
    }

    // Section 3: Ovulatory Dysfunction (OD)
    if (irregularPeriods) {
      scores.A += 2;
      scores.B += 2;
      scores.D += 2;
    } else {
      scores.C += 2; // Regular periods indicate phenotype C
    }

    // Section 4: Polycystic Ovaries (PCO)
    if (polycysticOvaries) {
      scores.A += 2;
      scores.C += 2;
      scores.D += 2;
    } else {
      scores.B += 2; // No PCO indicates phenotype B
    }

    // Section 5: Hirsutism (mFG Score)
    if (hirsutism) {
      scores.A += 2;
      scores.B += 2;
      scores.C += 2;
    } else {
      scores.D += 2; // Lower mFG score suggests phenotype D
    }

    return scores;
  };

  const determineMostLikelyType = (scores: PCOSTypeResult): string => {
    const sortedScores = Object.entries(scores).sort(([, a], [, b]) => b - a);
    const highestType = sortedScores[0][0];

    switch (highestType) {
      case "A":
        return "Phenotype A: Hyperandrogenism, Ovulatory Dysfunction, Polycystic Ovaries";
      case "B":
        return "Phenotype B: Hyperandrogenism and Ovulatory Dysfunction";
      case "C":
        return "Phenotype C: Hyperandrogenism and Polycystic Ovaries";
      case "D":
        return "Phenotype D: Ovulatory Dysfunction and Polycystic Ovaries";
      default:
        return "Unknown PCOS Type";
    }
  };

  const resultScores = quizCompleted ? calculatePCOSTypeScores() : null;
  const mostLikelyType = quizCompleted ? determineMostLikelyType(resultScores!) : null;

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="self-start my-14">
        <Link to='/'>
          <p className="text-border self-start px-24 font-semibold text-[#C9C9C9] hover:text-black transition duration-200 cursor-pointer">
            Back home
          </p>
        </Link>
      </div>
      {!quizCompleted ? (
        <div className="flex flex-col space-y-16 w-2/3">
          <div className="flex justify-end">
            <AnimatedCircularProgressBar
              max={questions.length}
              value={currentQuestion}
              min={0}
              gaugePrimaryColor={"#C9C9C9"}
              gaugeSecondaryColor={"#E38056"}
            />
          </div>
          <div className="h-[10rem]">
            <p className="text-3xl font-bold text-center">
              {questions[currentQuestion].text}
            </p>
          </div>
          <div className="flex items-center justify-center w-full gap-12">
            <button
              className="px-8 py-3 text-xl text-background rounded-lg bg-secondary"
              onClick={() => handleAnswer(true)}
            >
              Yes
            </button>
            <button
              className="px-8 py-3 text-xl text-background rounded-lg bg-secondary"
              onClick={() => handleAnswer(false)}
            >
              No
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-24 text-center">
          <h2 className="text-3xl font-bold">Your PCOS Type Breakdown</h2>

          {resultScores && (
            <div>
              <p className="text-2xl font-semibold">Phenotype A: {resultScores.A} points</p>
              <p className="text-2xl font-semibold">Phenotype B: {resultScores.B} points</p>
              <p className="text-2xl font-semibold">Phenotype C: {resultScores.C} points</p>
              <p className="text-2xl font-semibold">Phenotype D: {resultScores.D} points</p>
            </div>
          )}

          {mostLikelyType && (
            <div>
              <h3 className="text-4xl text-secondary font-bold">{mostLikelyType}</h3>
            </div>
          )}

          <p className="text-lg">
            This is a rough estimate based on your answers. The phenotype with the highest points is the most likely.
          </p>
          <div>
            <p>We will send you more information about your type and what you can do.</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
              className="p-3 rounded-lg mt-4"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Quiz;
