import React from "react";
import footerLogo from "../assets/icons/footerLogo.png";
import footerFacebook from "../assets/icons/footerFacebook.png";
import footerTwitter from "../assets/icons/footerTwitter.png";
import footerInsta from "../assets/icons/footerInsta.png";
import footerTiktok from "../assets/icons/footerTiktok.png";
import footerLinkedIn from "../assets/icons/footerLinkedIn.png";
import footerLogoMobile from "../assets/icons/footerLogoMobile.png";

const Footer = () => {
  return (
    <footer
      id="contact"
      className="w-full bg-[#5C3411] text-popover px-8 pb-14 pt-20 flex flex-col items-start lg:items-center justify-center lg:px-32"
      aria-labelledby="footer-heading"
    >
      {/* Contact Information Section */}
      <div className="w-full max-w-screen-xl flex flex-col items-start">
        <div className="flex flex-col items-start">
          <img
            src={footerLogoMobile}
            alt="Paia Logo Mobile"
            className="w-40 mb-4 lg:hidden"
          />
          <img
            src={footerLogo}
            alt="Paia Logo"
            className="w-auto h-[4.3rem] lg:h-[7.25rem] mb-4 hidden lg:block"
          />
          <h3
            id="footer-heading"
            className="text-[20px] lg:text-[34px] font-semibold"
          >
            Contact Us:
          </h3>
          <p className="text-[18px] lg:text-[30px]">
            <a href="mailto:hello@paiapcos.com" aria-label="Email Paia PCOS">
              hello@paiapcos.com
            </a>
          </p>
        </div>
        
        {/* Social Media Links for Larger Screens */}
        <div className="hidden lg:flex items-end justify-between it w-full max-w-screen-xl pt-12 pb-4 lg:mt-0">
          <p className="text-[20px] font-light text-[#FCF1E7] mb-6 lg:mb-0">
            © 2024 Paia PCOS
          </p>
          <div className="flex items-center justify-center gap-2 lg:justify-end">
            <a
              href="https://www.instagram.com/paiapcos/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <img src={footerInsta} alt="Instagram" className="w-auto h-[3.3rem]" />
            </a>
            <a
              href="https://www.tiktok.com/@paiapcos"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="TikTok"
            >
              <img src={footerTiktok} alt="TikTok" className="w-auto h-[3.3rem]" />
            </a>
            <a
              href="https://x.com/PaiaPcos"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <img src={footerTwitter} alt="Twitter" className="w-auto h-[3.3rem]" />
            </a>
            <a
              href="https://www.linkedin.com/company/paia-pcos/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <img src={footerLinkedIn} alt="LinkedIn" className="w-auto h-[3.3rem]" />
            </a>
            <a
              href="https://www.facebook.com/people/Paia/61561575683991/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <img src={footerFacebook} alt="Facebook" className="w-auto h-[3.3rem]" />
            </a>
          </div>
        </div>
      </div>

      {/* Social Media and Footer Links on Mobile */}
      <div className="lg:hidden flex flex-col items-start mt-12 lg:mt-0">
        <div className="flex gap-2 mb-6">
          <a
            href="https://www.instagram.com/paiapcos/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <img src={footerInsta} alt="Instagram" className="w-auto h-[3.25rem]" />
          </a>
          <a
            href="https://www.tiktok.com/@paiapcos"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="TikTok"
          >
            <img src={footerTiktok} alt="TikTok" className="w-auto h-[3.25rem]" />
          </a>
          <a
            href="https://x.com/PaiaPcos"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <img src={footerTwitter} alt="Twitter" className="w-auto h-[3.25rem]" />
          </a>
          <a
            href="https://www.linkedin.com/company/paia-pcos/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <img src={footerLinkedIn} alt="LinkedIn" className="w-auto h-[3.25rem]" />
          </a>
          <a
            href="https://www.facebook.com/people/Paia/61561575683991/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <img src={footerFacebook} alt="Facebook" className="w-auto h-[3.25rem]" />
          </a>
        </div>

        <p className="text-left text-[13px] text-[#FCF1E7]">
          © 2024 Paia PCOS
        </p>
      </div>
    </footer>
  );
};

export default Footer;
