import React from "react";

const Consultation = () => {
  const questions = [
    "Which is better for me: Inositol or Metformin?",
    "Is it possible to reverse or cure PCOS?",
    "If I get pregnant, how could PCOS impact my pregnancy?",
    "Will my PCOS diagnosis affect my ability to conceive?",
    "Do I still need to use contraception if my periods are irregular?",
    "What is the most effective contraceptive method for women with PCOS?",
    "Is there a heightened risk of endometrial cancer with PCOS, and what symptoms should I watch for?",
  ];
  return (
    <div className="min-h-screen w-screen flex flex-col items-center mt-36 px-32">
      <div className="text-left flex flex-col space-y-4 my-10">
        <h1 className="text-lg text-border font-semibold">
          Make Your PCOS Diagnosis Consultation More Productive
        </h1>
        <h2 className="text-secondary text-4xl font-bold">
          Essential Questions Developed by Our In-House Gynecologist to Help You
          Take Control of Your Health
        </h2>
        <div className="flex flex-col space-y-2 pt-24">
          <p className="text-2xl text-center text-border font-semibold">
            If you've been diagnosed with Polycystic Ovary Syndrome (PCOS),
            preparing for your doctor's visit is key to managing your condition
            effectively.
          </p>
        </div>
      </div>
      <div className="flex flex-wrap justify-center items-center gap-4 mb-8">
        {questions.map((question, index) => (
          <div
            key={index}
            className="bg-white p-10 rounded-lg shadow-sm w-fit max-w-[35rem]  text-center"
          >
            <p className="text-xl font-bold">{question}</p>
          </div>
        ))}
      </div>
      <div>

      </div>
    </div>
  );
};

export default Consultation;
