import React from "react";
import target from "../assets/images/mission.png";

const PaiaMission = () => {
  const missionText =
    "To raise awareness about PCOS, accelerate diagnosis, guide effective treatment, and prevent associated health risks. We aim to provide comprehensive support and resources to help women navigate their PCOS journey with confidence.";

  return (
    <div className="w-screen">
      <div className="w-screen flex flex-col items-center">
        <h1 className="text-center text-secondary font-bold text-[32px] lg:text-[38px] py-12">
          Our Mission
        </h1>
        <div
          style={{
            background:
              "linear-gradient(to top right, #FCDBC1, #FACCC6, #FBE9DA, #F4F4F3)",
          }}
          className=" w-screen text-[36px] text-secondary font-bold py-12 px-6 text-center lg:text-left lg:py-16"
        >
          <div className="w-full flex flex-col lg:flex-row items-center justify-center gap-8 lg:gap-18">
            <p className="text-[26px] lg:text-[36px] lg:max-w-[36.5rem] leading-[2.9rem]">
              {missionText}
            </p>
            <img
              src={target}
              alt="target"
              className=" h-[10rem] lg:h-[14rem] pr-10 lg:pr-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaiaMission;
