import React from "react";
import { MotionConfig, motion } from "framer-motion";

const AnimatedHamburgerBtn: React.FC<{ setIsOpen: React.Dispatch<React.SetStateAction<boolean>>, isOpen: boolean }> = ({ setIsOpen, isOpen }) => {
  return (
    <MotionConfig
      transition={{
        duration: 0.5,
        ease: "easeInOut",
      }}
    >
      <motion.button
        initial={false}
        animate={isOpen ? "open" : "closed"}
        onClick={() => setIsOpen((pv) => !pv)}
        className="relative h-10 w-fit bg-transparent"
      >
        <motion.span
          variants={VARIANTS.top}
          className="absolute h-[3px] w-[38px] bg-secondary rounded-full"
          style={{ y: "-50%", left: "50%", x: "-50%", top: "20%" }} // More spacing: 20% top
        />
        <motion.span
          variants={VARIANTS.middle}
          className="absolute h-[3px] w-[38px] bg-secondary rounded-full"
          style={{ left: "50%", x: "-50%", top: "50%", y: "-50%" }} // Middle at 50%
        />
        <motion.span
          variants={VARIANTS.bottom}
          className="absolute h-[3px] w-[38px] bg-secondary rounded-full"
          style={{
            x: "-50%",
            y: "50%",
            bottom: "20%", // More spacing: 20% bottom
            left: "50%",
          }}
        />
      </motion.button>
    </MotionConfig>
  );
};

const VARIANTS = {
  top: {
    open: {
      rotate: ["0deg", "0deg", "45deg"],
      top: ["20%", "50%", "50%"], // Adjusted top for more spacing
    },
    closed: {
      rotate: ["45deg", "0deg", "0deg"],
      top: ["50%", "50%", "20%"], // Adjusted top for more spacing
    },
  },
  middle: {
    open: {
      rotate: ["0deg", "0deg", "-45deg"],
    },
    closed: {
      rotate: ["-45deg", "0deg", "0deg"],
    },
  },
  bottom: {
    open: {
      rotate: ["0deg", "0deg", "-45deg"],
      bottom: ["20%", "50%", "50%"], // Adjusted bottom for more spacing
      left: "50%",
    },
    closed: {
      rotate: ["-45deg", "0deg", "0deg"],
      bottom: ["50%", "50%", "20%"], // Adjusted bottom for more spacing
      left: "50%",
    },
  },
};

export default AnimatedHamburgerBtn;
