import React from "react";
import { useState, useEffect } from "react";
import scrollUpImage from "../assets/icons/scrollUp.png";
import Hero from "../components/Hero";
import Signup from "../components/Signup";
import About from "../components/About";
import Team from "../components/Team";
import Connect from "../components/Connect";
import Events from "../components/Events";
import PaiaMission from "../components/PaiaMission";

const Landing = () => {
  const [showScrollUpButton, setShowScrollUpButton] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const signupSection = document.getElementById("signup");

      // Null check to make sure the element exists
      if (signupSection) {
        const rect = signupSection.getBoundingClientRect();
        if (rect.bottom < 0) {
          setShowScrollUpButton(true);
        } else {
          setShowScrollUpButton(false);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="App" id="landing">
      <style>{`
      html {
        scroll-behavior: smooth;
      }
    `}</style>

      <div className="">
        <Hero />
        <Signup />
        <About />
        <Team />
        <PaiaMission />
        <Connect />
        <Events />
      </div>
      {showScrollUpButton && (
        <button
          onClick={() => {
            const landingSection = document.getElementById("landing");
            if (landingSection) {
              landingSection.scrollIntoView({ behavior: "smooth" });
            }
          }}
          className="fixed bottom-16 right-10 lg:bottom-32 lg:right-16 z-50"
        >
          <img src={scrollUpImage} alt="Scroll to top" className="w-auto h-[3.4rem] lg:h-[4.8rem]" />
        </button>
      )}
    </div>
  );
};

export default Landing;
