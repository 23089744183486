export const questions = [
  {
    key: "highAndrogens",
    text: "Have you been diagnosed with high androgens based on blood tests or clinical signs like hirsutism?",
  },
  {
    key: "elevatedTestosterone",
    text: "Do your blood tests show elevated testosterone levels?",
  },
  {
    key: "irregularPeriods",
    text: "Have you been diagnosed with irregular periods (fewer than 8 cycles per year)?",
  },
  {
    key: "polycysticOvaries",
    text: "Have you been diagnosed with polycystic ovaries (PCO) based on ultrasound results?",
  },
  {
    key: "hirsutism",
    text: "Is your mFG (modified Ferriman-Gallwey) score for hirsutism 4 or higher?",
  }
];
