import React from "react";
import { Link } from "react-router-dom";

const Learn = () => {
  return (
    <div className="mx-auto flex flex-col items-start justify-center min-h-screen space-y-8 text-black py-52 px-10 md:w-2/3 lg:w-1/2">
      <div>
        <h2 className="italic text-md text-secondary">
          Resources coming soon.
        </h2>
      </div>
      {/* Friendly Message */}
      <h1 className="text-left text-primary-foreground text-6xl font-bold">
        PCOS
      </h1>
      <p className="text-left text-secondary text-[18px] lg:text-[24px]">
          Polycystic Ovary Syndrome (PCOS) is an endocrine disorder affecting around 10% of women globally,
          characterised by hormonal imbalances. It often presents with symptoms like irregular periods,
          elevated androgen levels, and enlarged ovaries containing multiple immature follicles.
      </p>
      <p className="text-left text-secondary text-[18px] lg:text-[24px]">
          Diagnosis may involve identifying two of these signs.
          Importantly, PCOS is a spectrum; symptoms and severity can vary widely,
          leading to diverse experiences among those affected.
      </p>
      <div className="my-6">
        <Link to="https://www.monash.edu/__data/assets/pdf_file/0003/3379521/Evidence-Based-Guidelines-2023.pdf" target="_blank">
          <button
            aria-label="Submit sign up form"
            className="bg-secondary w-fit rounded-[33px] hover:bg-secondary/80 transition duration-200 text-white py-2 px-16"
          >
            Learn more
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Learn;
