import React, { useState } from "react";
import { createClient } from "@supabase/supabase-js";
import validator from "validator";

const supabaseUrl = "https://hzprryancrhfcpvudiqp.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imh6cHJyeWFuY3JoZmNwdnVkaXFwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjA5NTM5MjQsImV4cCI6MjAzNjUyOTkyNH0.oPDN-20n1XqP47yqTlrL7FAgdG9RrERhJdf0gxBqd38";
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const Signup = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  // Explicitly set the type of `email` parameter to `string`
  const isEmailExists = async (email: string) => {
    const { data, error } = await supabase
      .from("emails")
      .select("emails_web")
      .eq("emails_web", email);

    if (error) {
      console.error("Error checking email existence:", error);
      return false;
    }

    return data.length > 0;
  };

  const sendEmail = async () => {
    if (!name) {
      setError("Please enter your name");
      return;
    }
    if (!email) {
      setError("Please enter your email");
      return;
    }
    if (!validator.isEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const exists = await isEmailExists(email);
      if (exists) {
        setError("Email address already exists");
        setLoading(false);
        return;
      }

      const { data, error } = await supabase
        .from("emails")
        .insert([{ name: name, emails_web: email }]);

      if (error) {
        setError(error.message);
      } else {
        setConfirmation(true);
        setEmail("");
        setName("");

        setTimeout(() => {
          setConfirmation(false);
        }, 4000);
      }
    } catch (error) {
      console.error("Error saving email:", error);
      setError("Failed to save email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section
      id="signup"
      aria-labelledby="signup-heading"
      className="col-span-6 flex flex-col items-center justify-center px-8 h-[24rem] lg:h-[27rem] bg-card"
    >
      <h2
        id="signup-heading"
        className="text-2xl mb-4 mt-4 italic text-[28px] font-semibold lg:text-[38px] lg:font-bold lg:w-1/2 leading-[2.2rem] text-center"
        style={{ color: "#5C3411" }}
      >
        Be the first to experience the launch of our app!
      </h2>
      {confirmation ? (
        <p className="mt-5 text-md font-medium">Thank you! Your email was saved successfully.</p>
      ) : (
        <div className="flex flex-col items-center">
          <div className="h-[2rem]">
            {error && <p className="mt-1 mb-1 text-[#B30505] text-md">{error}</p>}
          </div>
          <label htmlFor="name" className="sr-only">
            Name
          </label>
          <input
            type="text"
            id="name"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="name"
            required
            aria-required="true"
            className={`border px-4 py-3 mb-4 mt-2 rounded-[33px] w-[20rem] lg:w-[35rem] placeholder:text-[#949494] placeholder:font-light focus:border-[#5C3411] focus:font-bold ${
              name ? "font-bold border-[#5C3411]" : ""
            }`}
          />
          <label htmlFor="email" className="sr-only">
            Email
          </label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            required
            aria-required="true"
            className={`border px-4 py-3 mb-4 mt-2 rounded-[33px] w-[20rem] lg:w-[35rem] placeholder:text-[#949494] placeholder:font-light focus:border-[#5C3411] focus:font-bold ${
              email ? "font-bold border-[#5C3411]" : ""
            }`}
          />
          {loading ? (
            <p className="mt-2 text-center">Submitting...</p>
          ) : (
            <div className="m-6">
              <button
                aria-label="Submit sign up form"
                className="bg-secondary w-fit rounded-[33px] hover:bg-secondary/80 transition duration-200 text-white py-2 px-16"
                onClick={sendEmail}
              >
                Sign up
              </button>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Signup;
